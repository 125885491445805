import {
  AttributeContext,
  AttributeType,
  ContextualAttribute,
  UpdateCharacter,
  useCharacter,
  useUpdateCharacter,
} from '@/libs/compass-api';
import {
  EditableText,
  ImageWithUpload,
  SheetAttributeControl,
} from '@/libs/compass-core-composites';
import { Divider, Loader, Page, Skeleton, Stack, useDeviceSize } from '@/libs/compass-core-ui';
import { InventoryPanel } from '@/pages/sheet/components/inventory';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AttributeSort } from './attribute-sort';
import { useAttributeColumns } from './use-attribute-columns';
import { useSortAttributes } from './use-sort-attributes';

export const SimpleCharacterSheet = () => {
  const { attributes: _attributes } = useContext(AttributeContext);
  const { updateCharacter } = useUpdateCharacter(2000);

  const { mobile } = useDeviceSize();

  const attributes = _attributes.filter((a) => !a.autoGenerated);

  const { characterId } = useParams();
  const { character, loading } = useCharacter(characterId);

  const [sortValue, setSortValue] = useState<'Alphabetical' | 'Type'>('Alphabetical');
  const [columns, setColumns] = useState<number>(mobile ? 1 : 2);

  const { attributes: sortedAttributes } = useSortAttributes(attributes, sortValue);

  const actions = sortedAttributes.filter((a) => a.type === AttributeType.ACTION);

  const { firstColumn, secondColumn, thirdColumn } = useAttributeColumns(
    sortedAttributes.filter((a) => a.type !== AttributeType.ACTION),
    columns,
  );

  const handleUpdate = (input: Omit<UpdateCharacter, 'rulesetId' | 'id'>) => {
    if (!character) return;
    updateCharacter({ ...input, id: character.id });
  };

  return (
    <Page style={{ alignItems: 'center', marginTop: mobile ? 0 : 24 }}>
      <Stack height='100%' width='100%' maxWidth={1000} spacing={4} padding={mobile ? 1 : 2}>
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <ImageWithUpload
            loading={loading}
            src={character?.image?.src ?? ''}
            imageStyle={mobile ? { height: 150, width: 150 } : { height: 200, width: 200 }}
            onSelect={(image) => handleUpdate({ imageId: image.id })}
            onRemove={() => handleUpdate({ imageId: null })}
          />
          <Stack height='100%' justifyContent='space-between' alignItems='flex-end'>
            <Stack spacing={1}>
              {loading ? (
                <>
                  <Skeleton height={40} width={120} />
                  <Skeleton height={40} width={120} />
                </>
              ) : (
                <>
                  <EditableText
                    variant='h3'
                    inputProps={{ label: 'Name' }}
                    onChange={(name) => handleUpdate({ name: name as string })}
                    initialValue={character?.name}>
                    {character?.name}
                  </EditableText>
                </>
              )}
            </Stack>

            <AttributeSort
              columns={columns}
              sortValue={sortValue}
              onColumnsChange={setColumns}
              onSortChange={setSortValue}
            />

            <InventoryPanel />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction='row' gap={2} flexWrap='wrap'>
          {actions.map((attribute) => (
            <SheetAttributeControl
              key={attribute.id}
              attributeId={attribute.id}
              enableLogic
              viewMode
            />
          ))}
        </Stack>
        <Stack
          direction='row'
          width='100%'
          flexWrap='wrap'
          justifyContent={columns === 1 ? 'center' : 'space-between'}
          p={mobile ? 0 : 2}
          pt={mobile ? 0 : 5}
          sx={{
            overflow: 'auto',
            height: mobile ? 'calc(100vh - 330px)' : 'calc(100vh - 450px)',
          }}>
          {loading ? (
            <Stack height='100%' width='100%' justifyContent='center' alignItems='center'>
              <Loader color='info' />
            </Stack>
          ) : (
            [firstColumn, secondColumn, thirdColumn].map((col, i) => (
              <AttributeColumn key={i} enableLogic columnAttributes={col} loading={loading} />
            ))
          )}
        </Stack>
      </Stack>
    </Page>
  );
};

type Props = {
  loading?: boolean;
  columnAttributes: ContextualAttribute[];
  enableLogic: boolean;
};

const AttributeColumn = ({ columnAttributes, loading, enableLogic }: Props) => {
  if (loading) return null;

  return (
    <Stack spacing={4}>
      {columnAttributes.map((attribute) => (
        <SheetAttributeControl
          key={attribute.id}
          enableLogic={enableLogic}
          attributeId={attribute.id}
          viewMode
        />
      ))}
    </Stack>
  );
};
