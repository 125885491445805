import {
  Image,
  Ruleset,
  TemplateType,
  useDeleteBootstrap,
  useDeleteRuleset,
  useRuleset,
  useUpdateRuleset,
} from '@/libs/compass-api';
import { ImageWithUpload, TemplateLookup } from '@/libs/compass-core-composites';
import { DeleteButton, Divider } from '@/libs/compass-core-ui';
import { SettingsContext } from '@/libs/compass-web-utils';
import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { RulesetDefaults } from './ruleset-defaults';

export type FormValues = {
  title: string;
  description: string;
  isModule: boolean;
  createArchetypePages: boolean;
  createCreaturePages: boolean;
  archetypeTemplateId?: string | null;
  creatureTemplateId?: string | null;
};

export const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string(),
});

export const RulesetDetails = () => {
  const navigate = useNavigate();
  const { rulesetId } = useParams();
  const { ruleset, loading } = useRuleset(rulesetId);
  const { openSettingsModal, setSettingsPage } = useContext(SettingsContext);
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const { updateRuleset } = useUpdateRuleset();
  const { deleteRuleset, loading: deleting } = useDeleteRuleset();
  const { deleteBootstrap, loading: deletingBootstrap } = useDeleteBootstrap();

  const details = JSON.parse(ruleset?.details ?? '{}');

  const rulesetIsOriginal = !ruleset?.publishedRulesetId;

  const handleUpdate = async (update: Partial<Ruleset>) => {
    if (!ruleset) return;
    await updateRuleset({
      id: ruleset.id,
      ...update,
    });
  };

  const handleImageSave = async (image: Image | null) => {
    if (!ruleset) return;
    setImageLoading(true);
    await updateRuleset({
      id: ruleset.id,
      imageId: image?.id ?? null,
    });
    setImageLoading(false);
  };

  const handleDelete = async () => {
    if (!ruleset) return;
    await deleteRuleset(ruleset.id);

    localStorage.removeItem('last-viewed-ruleset-id');

    navigate('/');
    setSettingsPage('profile');
    openSettingsModal(false);
  };

  const handleDeletePages = async () => {
    await deleteBootstrap();
    setSettingsPage('profile');
    openSettingsModal(false);
    navigate(`/rulesets/${rulesetId}?selected=page-templates`);
  };

  return (
    <Stack padding={2} spacing={4} sx={{ height: '100%', overflowY: 'auto' }}>
      <Stack spacing={1}>
        <Text sx={{ opacity: 0.7 }}>Ruleset Settings</Text>
        <Divider />
      </Stack>

      <Stack direction='row' spacing={2} flexWrap='wrap'>
        <DeleteButton
          title='Remove autogenerated pages?'
          loading={deletingBootstrap}
          style={{ minWidth: 250 }}
          onDelete={handleDeletePages}
          label='Remove Autogenerated Pages'>
          <Text>Deletes all pages auto created from the rulebook document.</Text>
          <Text>Any edits made to these pages will not be recoverable.</Text>
        </DeleteButton>
        <DeleteButton
          title='Permanently delete ruleset?'
          loading={deleting}
          style={{ minWidth: 250 }}
          onDelete={handleDelete}
          label='Delete'>
          <Text>This will permanently remove this ruleset and all characters created from it.</Text>
          <Text>
            If this ruleset is published, it will no longer be available to any users who have not
            yet aquired it.
          </Text>
        </DeleteButton>
      </Stack>

      <Editable
        defaultValue={ruleset?.title}
        onSubmit={(title) => handleUpdate({ title })}
        fontSize='1.5rem'>
        <EditablePreview />
        <EditableInput />
      </Editable>

      <ImageWithUpload
        loading={imageLoading}
        src={ruleset?.image?.src ?? ''}
        onSelect={handleImageSave}
        onRemove={() => handleImageSave(null)}
        containerStyle={{ width: 150, height: 150 }}
        imageStyle={{ width: 150, height: 150 }}
      />

      {rulesetIsOriginal && (
        <FormControl display='flex' alignItems='center'>
          <FormLabel htmlFor='isModule' mb='0'>
            This is a generic module
          </FormLabel>
          <Switch
            id='isModule'
            isChecked={ruleset?.isModule ?? false}
            onChange={(e) => handleUpdate({ isModule: e.target.checked })}
          />
        </FormControl>
      )}

      <RulesetDefaults />

      <Stack>
        <Text>Select template to auto-create archetype pages</Text>
        <TemplateLookup
          type={TemplateType.PAGE}
          rulesetId={rulesetId ?? ''}
          loading={loading}
          templateId={details.archetypeTemplateId ?? undefined}
          onSelect={(temp) =>
            handleUpdate({
              details: JSON.stringify({
                ...details,
                archetypeTemplateId: temp?.id ?? null,
                createArchetypePages: !!temp,
              }),
            })
          }
        />
      </Stack>
    </Stack>
  );
};
