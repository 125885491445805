import {
  Attribute,
  AttributeData,
  AttributeType,
  ContextualAttribute,
  ContextualItem,
} from '@/libs/compass-api';
import { Logic, Operation, OperationType } from '@/libs/compass-planes';
import { getAllOperationsConnectedToInput } from './evaluation';
import { ContextualAttributeDependency } from './types';

/**
 * Injects any attributes not found in character's attribute data with those from ruleset
 *
 * Adds attribute description and logic from ruleset attribute to attribute data
 *
 * Adds attribute dependencies and dependents
 *
 * Does not add side effects of actions to modifiers! Action side effects are always manually triggered.
 */
export function buildContextualAttributes(
  attrData: AttributeData[],
  rulesetAttributes: Attribute[],
): ContextualAttribute[] {
  const allAttributes: ContextualAttribute[] = [];

  for (const rulesetAttribute of rulesetAttributes) {
    const correspondingData = attrData.find((attr) => attr.id === rulesetAttribute.id);

    allAttributes.push({
      id: rulesetAttribute.id,
      name: rulesetAttribute.name,
      type: rulesetAttribute.type,
      autoGenerated: false,
      source: '',
      description: rulesetAttribute.description ?? '',
      logic: JSON.parse(rulesetAttribute.logic ?? '[]'),
      logicDisabled: correspondingData?.logicDisabled ?? false,
      modifiers: [], //Set by map below
      value: correspondingData?.value ?? rulesetAttribute.defaultValue,
      defaultValue: rulesetAttribute.defaultValue,
      maxValue: rulesetAttribute.maxValue ?? undefined,
      restraints: rulesetAttribute.restraints ?? [],
      dependencies: getAttributeDependencies(rulesetAttribute, rulesetAttributes),
      dependents: getAttributeDependents(rulesetAttribute, rulesetAttributes),
    });
  }

  return allAttributes;
}

/**
 * For every property of each item in the ruleset, creates an attribute in cache to represent it.
 *
 * These attributes can be used to trigger events in logic calculations.
 *
 * Overrides property value with the first item of the type found in the character's inventory.
 */
export function autoGenerateItemPropertyAttributes(
  itemData: ContextualItem[],
  rulesetItems: Attribute[],
  rulesetId: string,
) {
  const autoGeneratedAttributes: Attribute[] = [];

  for (const rulesetItem of rulesetItems) {
    const characterItem = itemData.find((item) => item.id === rulesetItem.id);
    const data = JSON.parse(rulesetItem.data ?? '{}');
    const properties = JSON.parse(rulesetItem.logic ?? '[]')
      .filter((op: Operation) => op.type === OperationType.Property)
      .map((op: Operation) => op.data);

    const autoGeneratedProperties = [
      {
        name: 'Weight',
        value: data.weight ?? 1,
        type: AttributeType.NUMBER,
      },
    ];

    if (data.stackable !== false) {
      autoGeneratedProperties.push({
        name: 'Quantity',
        value: data.defaultQuantity ?? 1,
        type: AttributeType.NUMBER,
      });
    }

    if (data.equippable !== false) {
      autoGeneratedProperties.push({
        name: 'Equipped',
        value: false,
        type: AttributeType.BOOLEAN,
      });
    }

    for (const property of [...autoGeneratedProperties, ...properties]) {
      if (!property) continue; // Happens when a brand new property is created but no data is set
      const characterProperty = characterItem?.properties.find(
        (prop) => prop.id === property.name.toLowerCase(),
      );
      autoGeneratedAttributes.push({
        __typename: 'Attribute',
        rulesetId,
        id: `${rulesetItem.id}-${property.name.toLowerCase()}`,
        name: `${rulesetItem.name} ${property.name}`,
        type: property.type,
        description: rulesetItem.description ?? '',
        defaultValue: characterProperty?.value?.toString() ?? property.value?.toString() ?? '',
        logic: '[]',
        data: '{}',
      });
    }
  }

  return autoGeneratedAttributes;
}

function itemPropertyOperationDependsOnAttribute(operation: Operation, attribute: Attribute) {
  // The inventory should subscribe to changes in all item quantities
  if (operation.type === OperationType.Inventory) return attribute.id.includes('quantity');

  const matchedAttributeId = `${operation.attributeRef}-${operation.data?.selectedPropertyId}`;
  return matchedAttributeId === attribute.id;
}

export function getAttributeDependencies(attr: Attribute, attributes: Attribute[]) {
  const dependencies: ContextualAttributeDependency[] = [];

  for (const attribute of attributes) {
    const logic = JSON.parse(attr.logic ?? '[]') as Logic;
    if (
      logic.find(
        (operation) =>
          operation.type !== OperationType.SideEffect &&
          !operationIsConnectedToSideEffect(operation, logic) &&
          (operation.attributeRef === attribute.id ||
            itemPropertyOperationDependsOnAttribute(operation, attribute)),
      )
    ) {
      dependencies.push({
        id: attribute.id,
        name: attribute.name,
      });
    }
  }

  return dependencies;
}

export function getAttributeDependents(attr: AttributeData | Attribute, attributes: Attribute[]) {
  const dependencies: ContextualAttributeDependency[] = [];

  for (const attribute of attributes) {
    const logic = JSON.parse(attribute.logic ?? '[]') as Logic;
    if (
      logic.find(
        (operation) =>
          operation.type !== OperationType.SideEffect &&
          !operationIsConnectedToSideEffect(operation, logic) &&
          operation.attributeRef === attr.id,
      )
    ) {
      dependencies.push({
        id: attribute.id,
        name: attribute.name,
      });
    }
  }

  return dependencies;
}

function operationIsConnectedToSideEffect(
  operation: Operation,
  operations: Logic,
  _visited?: Set<string>,
): boolean {
  const visited = _visited ?? new Set<string>();
  visited.add(operation.id);
  for (const connection of operation.connections) {
    if (visited.has(connection.id)) continue;

    const connectedOp = operations.find((op) => op.id === connection.id);
    if (!connectedOp) continue;
    if (connectedOp?.type === OperationType.SideEffect) return true;
    return operationIsConnectedToSideEffect(connectedOp, operations, visited);
  }

  return false;
}

export function getConnectedOperationIds(operationId: string, operations: Logic): Set<string> {
  const connectedIds = new Set<string>();
  const visited = new Set<string>();

  function dfs(currentOperationId: string) {
    // Mark the current operation as visited
    visited.add(currentOperationId);

    // Find the current operation in the operations array
    const currentOperation = operations.find((op) => op.id === currentOperationId);

    if (currentOperation) {
      const connectedOperations = getAllOperationsConnectedToInput(currentOperation, operations);

      const allConnectionIds = connectedOperations.map((op) => op.id);

      // Iterate over the connections
      for (const connectionId of allConnectionIds) {
        // If we haven't visited this connection yet
        if (!visited.has(connectionId)) {
          // Add the connection to the set of connected ids
          connectedIds.add(connectionId);

          // Recursively call dfs with the connection
          dfs(connectionId);
        }
      }
    }
  }

  // Start the dfs with the provided operation id
  dfs(operationId);

  return connectedIds;
}

export function getParentOperationId(operationId: string, operations: Logic): string | null {
  const parent = operations.find((op) => {
    return op.connections.map((c) => c.id).includes(operationId);
  });

  return parent?.id ?? null;
}
