import { Coordinates, Logic } from '@/libs/compass-planes';
import { createContext, ReactNode } from 'react';
import { Attribute, AttributeType, Character, Chart } from '../gql';
import { AttributeData, UpdateCharacterItem } from '../types';

export type ContextualAttributeDependency = {
  name: string;
  id: string;
};

export type ContextualAttributeModifier = {
  sourceAttributeId: string;
  sourceAttributeName: string;
  logic: any;
};

export type ContextualAttribute = Omit<AttributeData, 'modifiers'> & {
  logic: any;
  modifiers: ContextualAttributeModifier[];
  valueWithModifiers?: string | number | null;
  defaultValue?: string;
  description: string;
  dependents: ContextualAttributeDependency[];
  dependencies: ContextualAttributeDependency[];
  autoGenerated: boolean;
};

export type ContextualItemProperty = {
  id: string; // attribute ID
  name: string;
  value: string | number | boolean | null;
  type: AttributeType;
};

export type ContextualItemAbility = {
  id: string;
  name: string;
  description: string;
};

export type ContextualItemData = {
  maxQuantity?: number;
  quantity?: number;
  weight: number;
  width: number;
  height: number;
  stackable: boolean;
  equippable: boolean;
  slots: string;
  imageSrc?: string;
  parentId?: string;
  x?: number;
  y?: number;
  rotation?: number;
};

export type ContextualItem = {
  id: string;
  instanceId: string;
  name: string;
  typeOf: string;
  description: string;
  logic: Logic;
  data: ContextualItemData;
  properties: ContextualItemProperty[];
  abilities: ContextualItemAbility[];
};

export interface UpdateCharacterAttributeProps {
  id: string;
  value?: string | number;
  valueWithModifiers?: string | number | null;
  reenableLogic?: boolean;
  manualChange?: boolean;
  actionName?: string;
  providedAttributeData?: ContextualAttribute[];
  providedCharacterId?: string;
  providedCharts?: Chart[];
}

export type IAttributeContext = {
  /**
   * The ruleset's attributes merged with their values from a character when one is available in context
   */
  attributes: ContextualAttribute[];
  items: ContextualItem[];
  rulesetItems: ContextualItem[];
  getItem: (id: string) => ContextualItem | undefined;
  updateItem: (props: UpdateCharacterItem) => void;
  updateItemsPositions: (updates: Array<{ id: string; x: number; y: number }>) => void;
  addItem: (
    item: Attribute,
    parentId?: string,
    coordinates?: Coordinates & { rotated: boolean },
  ) => void;
  removeItem: (id: string) => void;
  updateCharacterAttribute: (props: UpdateCharacterAttributeProps) => Promise<void>;
  getAttribute: (id?: string | null) => ContextualAttribute | undefined;
  triggerAction: (attributeId: string) => void;
  triggerItemAbility: (itemInstanceId: string, abilityOperationId: string) => void;
  loading: boolean;
  streamedCharacter?: Character;
};

/**
 * Provides the values of relevant attributes depending on app location.
 * Used to give data to attribute sheet controls and rulebook components.
 *
 * Calculates the logical value of all dependencies when an attribute is updated for a character.
 */
export const AttributeContext = createContext<IAttributeContext>(null!);

export const AttributeProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: IAttributeContext;
}) => <AttributeContext.Provider value={value}>{children}</AttributeContext.Provider>;
