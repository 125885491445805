/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CurrentUserUpdateInput = {
  username?: string | null,
  avatarId?: string | null,
  companion?: CompanionInput | null,
  onboarded?: boolean | null,
  preferences?: UserPreferencesInput | null,
};

export type CompanionInput = {
  name?: string | null,
  description?: string | null,
  src?: string | null,
  color?: string | null,
  animal?: string | null,
  model?: string | null,
};

export type UserPreferencesInput = {
  emailUpdates?: boolean | null,
  emailShares?: boolean | null,
  emailUnsubscribe?: boolean | null,
};

export type User = {
  __typename: "User",
  id: string,
  stripeId?: string | null,
  email: string,
  username: string,
  avatarSrc?: string | null,
  storageAllotment: number,
  companion: Companion,
  onboarded: boolean,
  preferences: UserPreferences,
  sheets:  Array<Sheet >,
  rulesets:  Array<Ruleset >,
  images:  Array<Image >,
  playtestRulesets:  Array<Ruleset >,
  collaboratorRulesets:  Array<Ruleset >,
  role: UserRole,
  membershipExpiration: string,
};

export type Companion = {
  __typename: "Companion",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  src?: string | null,
  color?: string | null,
  animal?: string | null,
  model: string,
};

export type UserPreferences = {
  __typename: "UserPreferences",
  emailUpdates: boolean,
  emailShares: boolean,
  emailUnsubscribe: boolean,
};

export type Sheet = {
  __typename: "Sheet",
  id: string,
  rulesetId?: string | null,
  pageId?: string | null,
  rulesetTitle?: string | null,
  type: SheetType,
  templateId?: string | null,
  templateName?: string | null,
  templateType?: TemplateType | null,
  userId: string,
  username: string,
  version: number,
  title: string,
  description: string,
  image?: Image | null,
  backgroundImage?: Image | null,
  details: string,
  components:  Array<SheetComponent >,
  tabs: string,
};

export enum SheetType {
  SHEET = "SHEET",
  TEMPLATE = "TEMPLATE",
}


export enum TemplateType {
  PAGE = "PAGE",
  SHEET = "SHEET",
}


export type Image = {
  __typename: "Image",
  id: string,
  src?: string | null,
  name: string,
  sortIndex: number,
  parentId?: string | null,
  details: string,
};

export type SheetComponent = {
  __typename: "SheetComponent",
  id: string,
  sheetId: string,
  rulesetId: string,
  type: string,
  label: string,
  description?: string | null,
  images?:  Array<Image > | null,
  locked: boolean,
  tabId: string,
  layer: number,
  style: string,
  data: string,
  groupId?: string | null,
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
};

export type Ruleset = {
  __typename: "Ruleset",
  id: string,
  version: number,
  published: boolean,
  images:  Array<Image >,
  approved?: boolean | null,
  live?: boolean | null,
  includesAI?: boolean | null,
  includesPDF?: boolean | null,
  explicit?: boolean | null,
  createdAt: string,
  isModule: boolean,
  rulesetPermissions: string,
  rulesetId?: string | null,
  publishedRulesetId?: string | null,
  rulesetTitle?: string | null,
  userId: string,
  createdBy: string,
  createdById: string,
  permissions: Array< string >,
  title: string,
  description: string,
  details: string,
  image?: Image | null,
  sheets:  Array<Sheet >,
  pages:  Array<Page >,
  modules:  Array<Ruleset >,
  playtesters:  Array<User >,
};

export type Page = {
  __typename: "Page",
  id: string,
  sheetId: string,
  rulesetId?: string | null,
  archetypeId?: string | null,
  characterId?: string | null,
  title: string,
  details: string,
  content: string,
  sortIndex: number,
  parentId?: string | null,
  sheet: Sheet,
};

export enum UserRole {
  USER = "USER",
  CREATOR = "CREATOR",
  PUBLISHER = "PUBLISHER",
}


export type CreateImage = {
  src?: string | null,
  name: string,
  sortIndex?: number | null,
  parentId?: string | null,
  details?: string | null,
};

export type UpdateImage = {
  id: string,
  name?: string | null,
  sortIndex?: number | null,
  parentId?: string | null,
  details?: string | null,
};

export type DeleteImage = {
  id: string,
};

export type DeleteImageResponse = {
  __typename: "DeleteImageResponse",
  safeToDeleteFile: boolean,
  fileKey: string,
};

export type CreateRuleset = {
  title: string,
  rulesetId?: string | null,
  description?: string | null,
  details?: string | null,
  imageId?: string | null,
};

export type UpdateRuleset = {
  id: string,
  isModule?: boolean | null,
  rulesetPermissions?: string | null,
  rulesetId?: string | null,
  title?: string | null,
  description?: string | null,
  details?: string | null,
  imageId?: string | null,
};

export type AddRulesetPermission = {
  rulesetId: string,
  userId: string,
  permission: string,
  shelved?: boolean | null,
};

export type RemoveRulesetPermission = {
  rulesetId: string,
  userId: string,
  permission: string,
};

export type UpdateRulesetPermission = {
  rulesetId: string,
  userId: string,
  permission: string,
  shelved: boolean,
};

export type DeleteRuleset = {
  id: string,
};

export type AddPlaytester = {
  rulesetId: string,
  userId: string,
};

export type RemovePlaytester = {
  rulesetId: string,
  userId: string,
};

export type PublishRuleset = {
  id: string,
  version: number,
};

export type UpdatePublishedRuleset = {
  id: string,
  live?: boolean | null,
  includesAI?: boolean | null,
  includesPDF?: boolean | null,
  explicit?: boolean | null,
  version?: number | null,
  price?: number | null,
  isModule?: boolean | null,
  rulesetPermissions?: string | null,
  rulesetId?: string | null,
  title?: string | null,
  description?: string | null,
  details?: string | null,
  imageId?: string | null,
};

export type AddToShelf = {
  id: string,
  isModule?: boolean | null,
};

export type AddModule = {
  moduleId: string,
  rulesetId: string,
};

export type RemoveModule = {
  moduleId: string,
  rulesetId: string,
};

export type CreatePage = {
  id?: string | null,
  title: string,
  templateId?: string | null,
  archetypeId?: string | null,
  characterId?: string | null,
  sortIndex?: number | null,
  rulesetId: string,
  content?: string | null,
  details?: string | null,
  parentId?: string | null,
};

export type CreatePageTemplate = {
  pageId: string,
  rulesetId: string,
  title?: string | null,
  description?: string | null,
};

export type UpdatePage = {
  id: string,
  rulesetId: string,
  archetypeId?: string | null,
  sheetId?: string | null,
  sortIndex?: number | null,
  title?: string | null,
  content?: string | null,
  details?: string | null,
  parentId?: string | null,
};

export type DeleteEntity = {
  id: string,
  rulesetId: string,
};

export type CreateChart = {
  rulesetId: string,
  title: string,
  fileKey: string,
};

export type Chart = {
  __typename: "Chart",
  id: string,
  rulesetId: string,
  moduleId?: string | null,
  moduleTitle?: string | null,
  title: string,
  fileKey: string,
  data?: Array< Array< string > > | null,
};

export type UpdateChart = {
  id: string,
  rulesetId: string,
  title?: string | null,
  fileKey?: string | null,
  data?: string | null,
};

export type CreateDocument = {
  rulesetId: string,
  title: string,
  fileKey: string,
};

export type Document = {
  __typename: "Document",
  id: string,
  rulesetId: string,
  moduleId?: string | null,
  moduleTitle?: string | null,
  title: string,
  fileKey: string,
};

export type UpdateDocument = {
  id: string,
  rulesetId: string,
  title?: string | null,
  fileKey?: string | null,
};

export type BootstrapRulebook = {
  rulesetId: string,
  id: string,
};

export type CreateAttribute = {
  rulesetId: string,
  name: string,
  description?: string | null,
  type: AttributeType,
  defaultValue: string,
  imageId?: string | null,
  data?: string | null,
  minValue?: number | null,
  maxValue?: number | null,
  category?: string | null,
  restraints?: Array< string > | null,
  sortChildId?: string | null,
};

export enum AttributeType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  COLOR = "COLOR",
  BOOLEAN = "BOOLEAN",
  ACTION = "ACTION",
  ITEM = "ITEM",
}


export type Attribute = {
  __typename: "Attribute",
  id: string,
  rulesetId: string,
  moduleId?: string | null,
  moduleTitle?: string | null,
  name: string,
  description?: string | null,
  data: string,
  type: AttributeType,
  defaultValue: string,
  minValue?: number | null,
  maxValue?: number | null,
  category?: string | null,
  restraints?: Array< string > | null,
  logic: string,
  sortChildId?: string | null,
  image?: Image | null,
  variation?: string | null,
  source?: string | null,
  sourceId?: string | null,
};

export type UpdateAttribute = {
  id: string,
  rulesetId: string,
  imageId?: string | null,
  name?: string | null,
  sheetId?: string | null,
  data?: string | null,
  description?: string | null,
  type?: AttributeType | null,
  defaultValue?: string | null,
  minValue?: number | null,
  maxValue?: number | null,
  category?: string | null,
  restraints?: Array< string > | null,
  sortChildId?: string | null,
  logic?: string | null,
  variation?: string | null,
};

export type UpdateAttributeOrder = {
  rulesetId: string,
  attributes: Array< UpdateAttribute >,
};

export type CreateArchetype = {
  rulesetId: string,
  moduleId?: string | null,
  title: string,
  category?: string | null,
  description?: string | null,
  imageId?: string | null,
};

export type Archetype = {
  __typename: "Archetype",
  id: string,
  rulesetId: string,
  moduleId?: string | null,
  moduleTitle?: string | null,
  category?: string | null,
  title: string,
  description?: string | null,
  image?: Image | null,
};

export type UpdateArchetype = {
  id: string,
  rulesetId: string,
  title?: string | null,
  category?: string | null,
  description?: string | null,
  imageId?: string | null,
};

export type CreateCharacter = {
  rulesetId: string,
  name: string,
  createdFromPublishedRuleset: boolean,
  description?: string | null,
  templateId?: string | null,
  imageId?: string | null,
};

export type Character = {
  __typename: "Character",
  id: string,
  rulesetId: string,
  rulesetTitle: string,
  username: string,
  createdFromPublishedRuleset: boolean,
  name: string,
  description?: string | null,
  attributes:  Array<Attribute >,
  pages:  Array<Page >,
  attributeData: string,
  itemData: string,
  image?: Image | null,
  sheet?: Sheet | null,
  streamTabId?: string | null,
};

export type UpdateCharacter = {
  id: string,
  name?: string | null,
  description?: string | null,
  imageId?: string | null,
  attributeData?: string | null,
  itemData?: string | null,
  templateId?: string | null,
  streamTabId?: string | null,
};

export type CreateSheet = {
  rulesetId?: string | null,
  pageId?: string | null,
  characterId?: string | null,
  type?: SheetType | null,
  templateId?: string | null,
  templateType?: TemplateType | null,
  templateName?: string | null,
  version?: number | null,
  title?: string | null,
  description?: string | null,
  imageId?: string | null,
  backgroundImageId?: string | null,
  details?: string | null,
  tabs?: string | null,
};

export type UpdateSheet = {
  id: string,
  rulesetId: string,
  pageId?: string | null,
  templateType?: TemplateType | null,
  title?: string | null,
  description?: string | null,
  imageId?: string | null,
  backgroundImageId?: string | null,
  details?: string | null,
  type?: SheetType | null,
  templateId?: string | null,
  templateName?: string | null,
  tabs?: string | null,
};

export type CreateSheetComponent = {
  id: string,
  rulesetId: string,
  sheetId: string,
  tabId: string,
  type: string,
  label: string,
  description?: string | null,
  locked: boolean,
  layer: number,
  style: string,
  data: string,
  groupId?: string | null,
  imageIds?: Array< string > | null,
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
};

export type UpdateSheetComponent = {
  id: string,
  characterId?: string | null,
  rulesetId: string,
  sheetId: string,
  label?: string | null,
  description?: string | null,
  type?: string | null,
  tabId?: string | null,
  locked?: boolean | null,
  groupId?: string | null,
  layer?: number | null,
  style?: string | null,
  imageIds?: Array< string > | null,
  removeImageIds?: Array< string > | null,
  data?: string | null,
  x?: number | null,
  y?: number | null,
  width?: number | null,
  height?: number | null,
  rotation?: number | null,
};

export type UpdateSheetComponentsResponse = {
  __typename: "UpdateSheetComponentsResponse",
  failedUpdateIds: Array< string >,
};

export type DeleteSheetComponent = {
  id: string,
  rulesetId: string,
  sheetId: string,
};

export type OfficialContent = {
  __typename: "OfficialContent",
  rulesets:  Array<Ruleset >,
  modules:  Array<Ruleset >,
};

export type TestConnection = {
  __typename: "TestConnection",
  message: string,
};

export type SearchUsersInput = {
  username?: string | null,
  email?: string | null,
};

export type EarlyAccessUserInput = {
  email?: string | null,
};

export type RulesetPermission = {
  __typename: "RulesetPermission",
  permission: string,
  user: User,
  shelved: boolean,
};

export type RulesetSalesPage = {
  __typename: "RulesetSalesPage",
  id: string,
  title: string,
  createdBy: string,
  createdAt: string,
  shelved: boolean,
  explicit: boolean,
  live: boolean,
  description: string,
  details: string,
  price: number,
  images?:  Array<Image > | null,
  currentUserHasPermission: boolean,
  includesAI: boolean,
  includesPDF: boolean,
};

export type GetEntity = {
  id: string,
  rulesetId: string,
};

export type GetSheetComponents = {
  sheetId: string,
  rulesetId: string,
  tabId?: string | null,
};

export type UpdateCurrentUserMutationVariables = {
  input: CurrentUserUpdateInput,
};

export type UpdateCurrentUserMutation = {
  updateCurrentUser:  {
    __typename: "User",
    id: string,
    stripeId?: string | null,
    email: string,
    username: string,
    avatarSrc?: string | null,
    storageAllotment: number,
    companion:  {
      __typename: "Companion",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      src?: string | null,
      color?: string | null,
      animal?: string | null,
      model: string,
    },
    onboarded: boolean,
    preferences:  {
      __typename: "UserPreferences",
      emailUpdates: boolean,
      emailShares: boolean,
      emailUnsubscribe: boolean,
    },
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    rulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    playtestRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    collaboratorRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    role: UserRole,
    membershipExpiration: string,
  },
};

export type CreateImageMutationVariables = {
  input: CreateImage,
};

export type CreateImageMutation = {
  createImage:  {
    __typename: "Image",
    id: string,
    src?: string | null,
    name: string,
    sortIndex: number,
    parentId?: string | null,
    details: string,
  },
};

export type UpdateImageMutationVariables = {
  input: UpdateImage,
};

export type UpdateImageMutation = {
  updateImage:  {
    __typename: "Image",
    id: string,
    src?: string | null,
    name: string,
    sortIndex: number,
    parentId?: string | null,
    details: string,
  },
};

export type UpdateImagesMutationVariables = {
  input: Array< UpdateImage >,
};

export type UpdateImagesMutation = {
  updateImages:  Array< {
    __typename: "Image",
    id: string,
    src?: string | null,
    name: string,
    sortIndex: number,
    parentId?: string | null,
    details: string,
  } >,
};

export type DeleteImageMutationVariables = {
  input: DeleteImage,
};

export type DeleteImageMutation = {
  deleteImage:  {
    __typename: "DeleteImageResponse",
    safeToDeleteFile: boolean,
    fileKey: string,
  },
};

export type ClearImagesMutation = {
  clearImages: string,
};

export type CreateRulesetMutationVariables = {
  input: CreateRuleset,
};

export type CreateRulesetMutation = {
  createRuleset:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type UpdateRulesetMutationVariables = {
  input: UpdateRuleset,
};

export type UpdateRulesetMutation = {
  updateRuleset:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type AddRulesetPermissionMutationVariables = {
  input: AddRulesetPermission,
};

export type AddRulesetPermissionMutation = {
  addRulesetPermission: string,
};

export type RemoveRulesetPermissionMutationVariables = {
  input: RemoveRulesetPermission,
};

export type RemoveRulesetPermissionMutation = {
  removeRulesetPermission: string,
};

export type UpdateRulesetPermissionMutationVariables = {
  input: UpdateRulesetPermission,
};

export type UpdateRulesetPermissionMutation = {
  updateRulesetPermission: string,
};

export type DeleteRulesetMutationVariables = {
  input: DeleteRuleset,
};

export type DeleteRulesetMutation = {
  deleteRuleset: string,
};

export type DeletePublishedRulesetMutationVariables = {
  input: DeleteRuleset,
};

export type DeletePublishedRulesetMutation = {
  deletePublishedRuleset: string,
};

export type AddPlaytesterMutationVariables = {
  input: AddPlaytester,
};

export type AddPlaytesterMutation = {
  addPlaytester: string,
};

export type RemovePlaytesterMutationVariables = {
  input: RemovePlaytester,
};

export type RemovePlaytesterMutation = {
  removePlaytester: string,
};

export type PublishRulesetMutationVariables = {
  input: PublishRuleset,
};

export type PublishRulesetMutation = {
  publishRuleset:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type UpdatePublishedRulesetMutationVariables = {
  input: UpdatePublishedRuleset,
};

export type UpdatePublishedRulesetMutation = {
  updatePublishedRuleset:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type AddToShelfMutationVariables = {
  input: AddToShelf,
};

export type AddToShelfMutation = {
  addToShelf:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type AddModuleMutationVariables = {
  input: AddModule,
};

export type AddModuleMutation = {
  addModule:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type RemoveModuleMutationVariables = {
  input: RemoveModule,
};

export type RemoveModuleMutation = {
  removeModule:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type CreatePageMutationVariables = {
  input: CreatePage,
};

export type CreatePageMutation = {
  createPage:  {
    __typename: "Page",
    id: string,
    sheetId: string,
    rulesetId?: string | null,
    archetypeId?: string | null,
    characterId?: string | null,
    title: string,
    details: string,
    content: string,
    sortIndex: number,
    parentId?: string | null,
    sheet:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    },
  },
};

export type CreatePageTemplateMutationVariables = {
  input: CreatePageTemplate,
};

export type CreatePageTemplateMutation = {
  createPageTemplate:  {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  },
};

export type UpdatePagesMutationVariables = {
  input: Array< UpdatePage >,
};

export type UpdatePagesMutation = {
  updatePages:  Array< {
    __typename: "Page",
    id: string,
    sheetId: string,
    rulesetId?: string | null,
    archetypeId?: string | null,
    characterId?: string | null,
    title: string,
    details: string,
    content: string,
    sortIndex: number,
    parentId?: string | null,
    sheet:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    },
  } >,
};

export type DeletePageMutationVariables = {
  input: DeleteEntity,
};

export type DeletePageMutation = {
  deletePage: string,
};

export type CreateChartMutationVariables = {
  input: CreateChart,
};

export type CreateChartMutation = {
  createChart:  {
    __typename: "Chart",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
    data?: Array< Array< string > > | null,
  },
};

export type UpdateChartMutationVariables = {
  input: UpdateChart,
};

export type UpdateChartMutation = {
  updateChart:  {
    __typename: "Chart",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
    data?: Array< Array< string > > | null,
  },
};

export type DeleteChartMutationVariables = {
  input: DeleteEntity,
};

export type DeleteChartMutation = {
  deleteChart: string,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocument,
};

export type CreateDocumentMutation = {
  createDocument:  {
    __typename: "Document",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
  },
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocument,
};

export type UpdateDocumentMutation = {
  updateDocument:  {
    __typename: "Document",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
  },
};

export type DeleteDocumentMutationVariables = {
  input: DeleteEntity,
};

export type DeleteDocumentMutation = {
  deleteDocument: string,
};

export type BootstrapRulebookMutationVariables = {
  input: BootstrapRulebook,
};

export type BootstrapRulebookMutation = {
  bootstrapRulebook: string,
};

export type DeleteBootstrapMutationVariables = {
  rulesetId: string,
};

export type DeleteBootstrapMutation = {
  deleteBootstrap: string,
};

export type CreateAttributeMutationVariables = {
  input: CreateAttribute,
};

export type CreateAttributeMutation = {
  createAttribute:  {
    __typename: "Attribute",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    name: string,
    description?: string | null,
    data: string,
    type: AttributeType,
    defaultValue: string,
    minValue?: number | null,
    maxValue?: number | null,
    category?: string | null,
    restraints?: Array< string > | null,
    logic: string,
    sortChildId?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    variation?: string | null,
    source?: string | null,
    sourceId?: string | null,
  },
};

export type UpdateAttributeMutationVariables = {
  input: UpdateAttribute,
};

export type UpdateAttributeMutation = {
  updateAttribute:  {
    __typename: "Attribute",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    name: string,
    description?: string | null,
    data: string,
    type: AttributeType,
    defaultValue: string,
    minValue?: number | null,
    maxValue?: number | null,
    category?: string | null,
    restraints?: Array< string > | null,
    logic: string,
    sortChildId?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    variation?: string | null,
    source?: string | null,
    sourceId?: string | null,
  },
};

export type DeleteAttributeMutationVariables = {
  input: DeleteEntity,
};

export type DeleteAttributeMutation = {
  deleteAttribute: string,
};

export type UpdateAttributeOrderMutationVariables = {
  input: UpdateAttributeOrder,
};

export type UpdateAttributeOrderMutation = {
  updateAttributeOrder: string,
};

export type CreateArchetypeMutationVariables = {
  input: CreateArchetype,
};

export type CreateArchetypeMutation = {
  createArchetype:  {
    __typename: "Archetype",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    category?: string | null,
    title: string,
    description?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
  },
};

export type UpdateArchetypeMutationVariables = {
  input: UpdateArchetype,
};

export type UpdateArchetypeMutation = {
  updateArchetype:  {
    __typename: "Archetype",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    category?: string | null,
    title: string,
    description?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
  },
};

export type DeleteArchetypeMutationVariables = {
  input: DeleteEntity,
};

export type DeleteArchetypeMutation = {
  deleteArchetype: string,
};

export type CreateCharacterMutationVariables = {
  input: CreateCharacter,
};

export type CreateCharacterMutation = {
  createCharacter:  {
    __typename: "Character",
    id: string,
    rulesetId: string,
    rulesetTitle: string,
    username: string,
    createdFromPublishedRuleset: boolean,
    name: string,
    description?: string | null,
    attributes:  Array< {
      __typename: "Attribute",
      id: string,
      rulesetId: string,
      moduleId?: string | null,
      moduleTitle?: string | null,
      name: string,
      description?: string | null,
      data: string,
      type: AttributeType,
      defaultValue: string,
      minValue?: number | null,
      maxValue?: number | null,
      category?: string | null,
      restraints?: Array< string > | null,
      logic: string,
      sortChildId?: string | null,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      variation?: string | null,
      source?: string | null,
      sourceId?: string | null,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    attributeData: string,
    itemData: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheet?:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } | null,
    streamTabId?: string | null,
  },
};

export type UpdateCharacterMutationVariables = {
  input: UpdateCharacter,
};

export type UpdateCharacterMutation = {
  updateCharacter:  {
    __typename: "Character",
    id: string,
    rulesetId: string,
    rulesetTitle: string,
    username: string,
    createdFromPublishedRuleset: boolean,
    name: string,
    description?: string | null,
    attributes:  Array< {
      __typename: "Attribute",
      id: string,
      rulesetId: string,
      moduleId?: string | null,
      moduleTitle?: string | null,
      name: string,
      description?: string | null,
      data: string,
      type: AttributeType,
      defaultValue: string,
      minValue?: number | null,
      maxValue?: number | null,
      category?: string | null,
      restraints?: Array< string > | null,
      logic: string,
      sortChildId?: string | null,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      variation?: string | null,
      source?: string | null,
      sourceId?: string | null,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    attributeData: string,
    itemData: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheet?:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } | null,
    streamTabId?: string | null,
  },
};

export type DeleteCharacterMutationVariables = {
  id: string,
};

export type DeleteCharacterMutation = {
  deleteCharacter: string,
};

export type CreateSheetMutationVariables = {
  input: CreateSheet,
};

export type CreateSheetMutation = {
  createSheet:  {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  },
};

export type UpdateSheetMutationVariables = {
  input: UpdateSheet,
};

export type UpdateSheetMutation = {
  updateSheet:  {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  },
};

export type DeleteSheetMutationVariables = {
  input: DeleteEntity,
};

export type DeleteSheetMutation = {
  deleteSheet: string,
};

export type CreateSheetComponentsMutationVariables = {
  input: Array< CreateSheetComponent >,
};

export type CreateSheetComponentsMutation = {
  createSheetComponents:  Array< {
    __typename: "SheetComponent",
    id: string,
    sheetId: string,
    rulesetId: string,
    type: string,
    label: string,
    description?: string | null,
    images?:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } > | null,
    locked: boolean,
    tabId: string,
    layer: number,
    style: string,
    data: string,
    groupId?: string | null,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  } >,
};

export type UpdateSheetComponentsMutationVariables = {
  input: Array< UpdateSheetComponent >,
};

export type UpdateSheetComponentsMutation = {
  updateSheetComponents:  {
    __typename: "UpdateSheetComponentsResponse",
    failedUpdateIds: Array< string >,
  },
};

export type DeleteSheetComponentsMutationVariables = {
  input: Array< DeleteSheetComponent >,
};

export type DeleteSheetComponentsMutation = {
  deleteSheetComponents: string,
};

export type OfficialContentQuery = {
  officialContent:  {
    __typename: "OfficialContent",
    rulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
  },
};

export type TestConnectionQuery = {
  testConnection:  {
    __typename: "TestConnection",
    message: string,
  },
};

export type ImagesQuery = {
  images:  Array< {
    __typename: "Image",
    id: string,
    src?: string | null,
    name: string,
    sortIndex: number,
    parentId?: string | null,
    details: string,
  } >,
};

export type CurrentUserQuery = {
  currentUser:  {
    __typename: "User",
    id: string,
    stripeId?: string | null,
    email: string,
    username: string,
    avatarSrc?: string | null,
    storageAllotment: number,
    companion:  {
      __typename: "Companion",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      src?: string | null,
      color?: string | null,
      animal?: string | null,
      model: string,
    },
    onboarded: boolean,
    preferences:  {
      __typename: "UserPreferences",
      emailUpdates: boolean,
      emailShares: boolean,
      emailUnsubscribe: boolean,
    },
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    rulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    playtestRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    collaboratorRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    role: UserRole,
    membershipExpiration: string,
  },
};

export type SearchUsersQueryVariables = {
  input: SearchUsersInput,
};

export type SearchUsersQuery = {
  searchUsers?:  Array< {
    __typename: "User",
    id: string,
    stripeId?: string | null,
    email: string,
    username: string,
    avatarSrc?: string | null,
    storageAllotment: number,
    companion:  {
      __typename: "Companion",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      src?: string | null,
      color?: string | null,
      animal?: string | null,
      model: string,
    },
    onboarded: boolean,
    preferences:  {
      __typename: "UserPreferences",
      emailUpdates: boolean,
      emailShares: boolean,
      emailUnsubscribe: boolean,
    },
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    rulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    playtestRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    collaboratorRulesets:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    role: UserRole,
    membershipExpiration: string,
  } > | null,
};

export type EarlyAccessUserQueryVariables = {
  input: EarlyAccessUserInput,
};

export type EarlyAccessUserQuery = {
  earlyAccessUser?: string | null,
};

export type RulesetQueryVariables = {
  id: string,
};

export type RulesetQuery = {
  ruleset:  {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  },
};

export type RulesetsQuery = {
  rulesets:  Array< {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  } >,
};

export type PermittedRulesetsQuery = {
  permittedRulesets:  Array< {
    __typename: "Ruleset",
    id: string,
    version: number,
    published: boolean,
    images:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } >,
    approved?: boolean | null,
    live?: boolean | null,
    includesAI?: boolean | null,
    includesPDF?: boolean | null,
    explicit?: boolean | null,
    createdAt: string,
    isModule: boolean,
    rulesetPermissions: string,
    rulesetId?: string | null,
    publishedRulesetId?: string | null,
    rulesetTitle?: string | null,
    userId: string,
    createdBy: string,
    createdById: string,
    permissions: Array< string >,
    title: string,
    description: string,
    details: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheets:  Array< {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    modules:  Array< {
      __typename: "Ruleset",
      id: string,
      version: number,
      published: boolean,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      approved?: boolean | null,
      live?: boolean | null,
      includesAI?: boolean | null,
      includesPDF?: boolean | null,
      explicit?: boolean | null,
      createdAt: string,
      isModule: boolean,
      rulesetPermissions: string,
      rulesetId?: string | null,
      publishedRulesetId?: string | null,
      rulesetTitle?: string | null,
      userId: string,
      createdBy: string,
      createdById: string,
      permissions: Array< string >,
      title: string,
      description: string,
      details: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      pages:  Array< {
        __typename: "Page",
        id: string,
        sheetId: string,
        rulesetId?: string | null,
        archetypeId?: string | null,
        characterId?: string | null,
        title: string,
        details: string,
        content: string,
        sortIndex: number,
        parentId?: string | null,
        sheet:  {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        },
      } >,
      modules:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      playtesters:  Array< {
        __typename: "User",
        id: string,
        stripeId?: string | null,
        email: string,
        username: string,
        avatarSrc?: string | null,
        storageAllotment: number,
        companion:  {
          __typename: "Companion",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          src?: string | null,
          color?: string | null,
          animal?: string | null,
          model: string,
        },
        onboarded: boolean,
        preferences:  {
          __typename: "UserPreferences",
          emailUpdates: boolean,
          emailShares: boolean,
          emailUnsubscribe: boolean,
        },
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        rulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        playtestRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        collaboratorRulesets:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        role: UserRole,
        membershipExpiration: string,
      } >,
    } >,
    playtesters:  Array< {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    } >,
  } >,
};

export type PermittedUsersQueryVariables = {
  id: string,
};

export type PermittedUsersQuery = {
  permittedUsers:  Array< {
    __typename: "RulesetPermission",
    permission: string,
    user:  {
      __typename: "User",
      id: string,
      stripeId?: string | null,
      email: string,
      username: string,
      avatarSrc?: string | null,
      storageAllotment: number,
      companion:  {
        __typename: "Companion",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        src?: string | null,
        color?: string | null,
        animal?: string | null,
        model: string,
      },
      onboarded: boolean,
      preferences:  {
        __typename: "UserPreferences",
        emailUpdates: boolean,
        emailShares: boolean,
        emailUnsubscribe: boolean,
      },
      sheets:  Array< {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      } >,
      rulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      images:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } >,
      playtestRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      collaboratorRulesets:  Array< {
        __typename: "Ruleset",
        id: string,
        version: number,
        published: boolean,
        images:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } >,
        approved?: boolean | null,
        live?: boolean | null,
        includesAI?: boolean | null,
        includesPDF?: boolean | null,
        explicit?: boolean | null,
        createdAt: string,
        isModule: boolean,
        rulesetPermissions: string,
        rulesetId?: string | null,
        publishedRulesetId?: string | null,
        rulesetTitle?: string | null,
        userId: string,
        createdBy: string,
        createdById: string,
        permissions: Array< string >,
        title: string,
        description: string,
        details: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        sheets:  Array< {
          __typename: "Sheet",
          id: string,
          rulesetId?: string | null,
          pageId?: string | null,
          rulesetTitle?: string | null,
          type: SheetType,
          templateId?: string | null,
          templateName?: string | null,
          templateType?: TemplateType | null,
          userId: string,
          username: string,
          version: number,
          title: string,
          description: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          backgroundImage?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          details: string,
          components:  Array< {
            __typename: "SheetComponent",
            id: string,
            sheetId: string,
            rulesetId: string,
            type: string,
            label: string,
            description?: string | null,
            images?:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } > | null,
            locked: boolean,
            tabId: string,
            layer: number,
            style: string,
            data: string,
            groupId?: string | null,
            x: number,
            y: number,
            width: number,
            height: number,
            rotation: number,
          } >,
          tabs: string,
        } >,
        pages:  Array< {
          __typename: "Page",
          id: string,
          sheetId: string,
          rulesetId?: string | null,
          archetypeId?: string | null,
          characterId?: string | null,
          title: string,
          details: string,
          content: string,
          sortIndex: number,
          parentId?: string | null,
          sheet:  {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          },
        } >,
        modules:  Array< {
          __typename: "Ruleset",
          id: string,
          version: number,
          published: boolean,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          approved?: boolean | null,
          live?: boolean | null,
          includesAI?: boolean | null,
          includesPDF?: boolean | null,
          explicit?: boolean | null,
          createdAt: string,
          isModule: boolean,
          rulesetPermissions: string,
          rulesetId?: string | null,
          publishedRulesetId?: string | null,
          rulesetTitle?: string | null,
          userId: string,
          createdBy: string,
          createdById: string,
          permissions: Array< string >,
          title: string,
          description: string,
          details: string,
          image?:  {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } | null,
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          pages:  Array< {
            __typename: "Page",
            id: string,
            sheetId: string,
            rulesetId?: string | null,
            archetypeId?: string | null,
            characterId?: string | null,
            title: string,
            details: string,
            content: string,
            sortIndex: number,
            parentId?: string | null,
            sheet:  {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            },
          } >,
          modules:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          playtesters:  Array< {
            __typename: "User",
            id: string,
            stripeId?: string | null,
            email: string,
            username: string,
            avatarSrc?: string | null,
            storageAllotment: number,
            companion:  {
              __typename: "Companion",
              id?: string | null,
              name?: string | null,
              description?: string | null,
              src?: string | null,
              color?: string | null,
              animal?: string | null,
              model: string,
            },
            onboarded: boolean,
            preferences:  {
              __typename: "UserPreferences",
              emailUpdates: boolean,
              emailShares: boolean,
              emailUnsubscribe: boolean,
            },
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            rulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            playtestRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            collaboratorRulesets:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            role: UserRole,
            membershipExpiration: string,
          } >,
        } >,
        playtesters:  Array< {
          __typename: "User",
          id: string,
          stripeId?: string | null,
          email: string,
          username: string,
          avatarSrc?: string | null,
          storageAllotment: number,
          companion:  {
            __typename: "Companion",
            id?: string | null,
            name?: string | null,
            description?: string | null,
            src?: string | null,
            color?: string | null,
            animal?: string | null,
            model: string,
          },
          onboarded: boolean,
          preferences:  {
            __typename: "UserPreferences",
            emailUpdates: boolean,
            emailShares: boolean,
            emailUnsubscribe: boolean,
          },
          sheets:  Array< {
            __typename: "Sheet",
            id: string,
            rulesetId?: string | null,
            pageId?: string | null,
            rulesetTitle?: string | null,
            type: SheetType,
            templateId?: string | null,
            templateName?: string | null,
            templateType?: TemplateType | null,
            userId: string,
            username: string,
            version: number,
            title: string,
            description: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            backgroundImage?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            details: string,
            components:  Array< {
              __typename: "SheetComponent",
              id: string,
              sheetId: string,
              rulesetId: string,
              type: string,
              label: string,
              description?: string | null,
              locked: boolean,
              tabId: string,
              layer: number,
              style: string,
              data: string,
              groupId?: string | null,
              x: number,
              y: number,
              width: number,
              height: number,
              rotation: number,
            } >,
            tabs: string,
          } >,
          rulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          images:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } >,
          playtestRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          collaboratorRulesets:  Array< {
            __typename: "Ruleset",
            id: string,
            version: number,
            published: boolean,
            images:  Array< {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } >,
            approved?: boolean | null,
            live?: boolean | null,
            includesAI?: boolean | null,
            includesPDF?: boolean | null,
            explicit?: boolean | null,
            createdAt: string,
            isModule: boolean,
            rulesetPermissions: string,
            rulesetId?: string | null,
            publishedRulesetId?: string | null,
            rulesetTitle?: string | null,
            userId: string,
            createdBy: string,
            createdById: string,
            permissions: Array< string >,
            title: string,
            description: string,
            details: string,
            image?:  {
              __typename: "Image",
              id: string,
              src?: string | null,
              name: string,
              sortIndex: number,
              parentId?: string | null,
              details: string,
            } | null,
            sheets:  Array< {
              __typename: "Sheet",
              id: string,
              rulesetId?: string | null,
              pageId?: string | null,
              rulesetTitle?: string | null,
              type: SheetType,
              templateId?: string | null,
              templateName?: string | null,
              templateType?: TemplateType | null,
              userId: string,
              username: string,
              version: number,
              title: string,
              description: string,
              details: string,
              tabs: string,
            } >,
            pages:  Array< {
              __typename: "Page",
              id: string,
              sheetId: string,
              rulesetId?: string | null,
              archetypeId?: string | null,
              characterId?: string | null,
              title: string,
              details: string,
              content: string,
              sortIndex: number,
              parentId?: string | null,
            } >,
            modules:  Array< {
              __typename: "Ruleset",
              id: string,
              version: number,
              published: boolean,
              approved?: boolean | null,
              live?: boolean | null,
              includesAI?: boolean | null,
              includesPDF?: boolean | null,
              explicit?: boolean | null,
              createdAt: string,
              isModule: boolean,
              rulesetPermissions: string,
              rulesetId?: string | null,
              publishedRulesetId?: string | null,
              rulesetTitle?: string | null,
              userId: string,
              createdBy: string,
              createdById: string,
              permissions: Array< string >,
              title: string,
              description: string,
              details: string,
            } >,
            playtesters:  Array< {
              __typename: "User",
              id: string,
              stripeId?: string | null,
              email: string,
              username: string,
              avatarSrc?: string | null,
              storageAllotment: number,
              onboarded: boolean,
              role: UserRole,
              membershipExpiration: string,
            } >,
          } >,
          role: UserRole,
          membershipExpiration: string,
        } >,
      } >,
      role: UserRole,
      membershipExpiration: string,
    },
    shelved: boolean,
  } >,
};

export type RulesetSalesPageQueryVariables = {
  id: string,
};

export type RulesetSalesPageQuery = {
  rulesetSalesPage:  {
    __typename: "RulesetSalesPage",
    id: string,
    title: string,
    createdBy: string,
    createdAt: string,
    shelved: boolean,
    explicit: boolean,
    live: boolean,
    description: string,
    details: string,
    price: number,
    images?:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } > | null,
    currentUserHasPermission: boolean,
    includesAI: boolean,
    includesPDF: boolean,
  },
};

export type PageTemplatesQueryVariables = {
  rulesetId: string,
};

export type PageTemplatesQuery = {
  pageTemplates:  Array< {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  } >,
};

export type PagesQueryVariables = {
  rulesetId: string,
};

export type PagesQuery = {
  pages:  Array< {
    __typename: "Page",
    id: string,
    sheetId: string,
    rulesetId?: string | null,
    archetypeId?: string | null,
    characterId?: string | null,
    title: string,
    details: string,
    content: string,
    sortIndex: number,
    parentId?: string | null,
    sheet:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    },
  } >,
};

export type PageQueryVariables = {
  input: GetEntity,
};

export type PageQuery = {
  page:  {
    __typename: "Page",
    id: string,
    sheetId: string,
    rulesetId?: string | null,
    archetypeId?: string | null,
    characterId?: string | null,
    title: string,
    details: string,
    content: string,
    sortIndex: number,
    parentId?: string | null,
    sheet:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    },
  },
};

export type ChartsQueryVariables = {
  rulesetId: string,
};

export type ChartsQuery = {
  charts:  Array< {
    __typename: "Chart",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
    data?: Array< Array< string > > | null,
  } >,
};

export type ChartQueryVariables = {
  input: GetEntity,
};

export type ChartQuery = {
  chart:  {
    __typename: "Chart",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
    data?: Array< Array< string > > | null,
  },
};

export type DocumentsQueryVariables = {
  rulesetId: string,
};

export type DocumentsQuery = {
  documents:  Array< {
    __typename: "Document",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
  } >,
};

export type DocumentQueryVariables = {
  input: GetEntity,
};

export type DocumentQuery = {
  document:  {
    __typename: "Document",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    title: string,
    fileKey: string,
  },
};

export type AttributesQueryVariables = {
  rulesetId: string,
  page?: number | null,
  type?: AttributeType | null,
};

export type AttributesQuery = {
  attributes:  Array< {
    __typename: "Attribute",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    name: string,
    description?: string | null,
    data: string,
    type: AttributeType,
    defaultValue: string,
    minValue?: number | null,
    maxValue?: number | null,
    category?: string | null,
    restraints?: Array< string > | null,
    logic: string,
    sortChildId?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    variation?: string | null,
    source?: string | null,
    sourceId?: string | null,
  } >,
};

export type AttributeQueryVariables = {
  input: GetEntity,
};

export type AttributeQuery = {
  attribute:  {
    __typename: "Attribute",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    name: string,
    description?: string | null,
    data: string,
    type: AttributeType,
    defaultValue: string,
    minValue?: number | null,
    maxValue?: number | null,
    category?: string | null,
    restraints?: Array< string > | null,
    logic: string,
    sortChildId?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    variation?: string | null,
    source?: string | null,
    sourceId?: string | null,
  },
};

export type ArchetypesQueryVariables = {
  rulesetId: string,
};

export type ArchetypesQuery = {
  archetypes:  Array< {
    __typename: "Archetype",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    category?: string | null,
    title: string,
    description?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
  } >,
};

export type ArchetypeQueryVariables = {
  input: GetEntity,
};

export type ArchetypeQuery = {
  archetype:  {
    __typename: "Archetype",
    id: string,
    rulesetId: string,
    moduleId?: string | null,
    moduleTitle?: string | null,
    category?: string | null,
    title: string,
    description?: string | null,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
  },
};

export type CharactersQuery = {
  characters:  Array< {
    __typename: "Character",
    id: string,
    rulesetId: string,
    rulesetTitle: string,
    username: string,
    createdFromPublishedRuleset: boolean,
    name: string,
    description?: string | null,
    attributes:  Array< {
      __typename: "Attribute",
      id: string,
      rulesetId: string,
      moduleId?: string | null,
      moduleTitle?: string | null,
      name: string,
      description?: string | null,
      data: string,
      type: AttributeType,
      defaultValue: string,
      minValue?: number | null,
      maxValue?: number | null,
      category?: string | null,
      restraints?: Array< string > | null,
      logic: string,
      sortChildId?: string | null,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      variation?: string | null,
      source?: string | null,
      sourceId?: string | null,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    attributeData: string,
    itemData: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheet?:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } | null,
    streamTabId?: string | null,
  } >,
};

export type CharacterQueryVariables = {
  id: string,
};

export type CharacterQuery = {
  character:  {
    __typename: "Character",
    id: string,
    rulesetId: string,
    rulesetTitle: string,
    username: string,
    createdFromPublishedRuleset: boolean,
    name: string,
    description?: string | null,
    attributes:  Array< {
      __typename: "Attribute",
      id: string,
      rulesetId: string,
      moduleId?: string | null,
      moduleTitle?: string | null,
      name: string,
      description?: string | null,
      data: string,
      type: AttributeType,
      defaultValue: string,
      minValue?: number | null,
      maxValue?: number | null,
      category?: string | null,
      restraints?: Array< string > | null,
      logic: string,
      sortChildId?: string | null,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      variation?: string | null,
      source?: string | null,
      sourceId?: string | null,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    attributeData: string,
    itemData: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheet?:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } | null,
    streamTabId?: string | null,
  },
};

export type SheetTemplatesQueryVariables = {
  rulesetId: string,
  published?: boolean | null,
};

export type SheetTemplatesQuery = {
  sheetTemplates:  Array< {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  } >,
};

export type SheetQueryVariables = {
  input: GetEntity,
};

export type SheetQuery = {
  sheet:  {
    __typename: "Sheet",
    id: string,
    rulesetId?: string | null,
    pageId?: string | null,
    rulesetTitle?: string | null,
    type: SheetType,
    templateId?: string | null,
    templateName?: string | null,
    templateType?: TemplateType | null,
    userId: string,
    username: string,
    version: number,
    title: string,
    description: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    backgroundImage?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    details: string,
    components:  Array< {
      __typename: "SheetComponent",
      id: string,
      sheetId: string,
      rulesetId: string,
      type: string,
      label: string,
      description?: string | null,
      images?:  Array< {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } > | null,
      locked: boolean,
      tabId: string,
      layer: number,
      style: string,
      data: string,
      groupId?: string | null,
      x: number,
      y: number,
      width: number,
      height: number,
      rotation: number,
    } >,
    tabs: string,
  },
};

export type SheetComponentsQueryVariables = {
  input: GetSheetComponents,
};

export type SheetComponentsQuery = {
  sheetComponents:  Array< {
    __typename: "SheetComponent",
    id: string,
    sheetId: string,
    rulesetId: string,
    type: string,
    label: string,
    description?: string | null,
    images?:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } > | null,
    locked: boolean,
    tabId: string,
    layer: number,
    style: string,
    data: string,
    groupId?: string | null,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  } >,
};

export type StreamCharacterSubscriptionVariables = {
  id: string,
};

export type StreamCharacterSubscription = {
  streamCharacter:  {
    __typename: "Character",
    id: string,
    rulesetId: string,
    rulesetTitle: string,
    username: string,
    createdFromPublishedRuleset: boolean,
    name: string,
    description?: string | null,
    attributes:  Array< {
      __typename: "Attribute",
      id: string,
      rulesetId: string,
      moduleId?: string | null,
      moduleTitle?: string | null,
      name: string,
      description?: string | null,
      data: string,
      type: AttributeType,
      defaultValue: string,
      minValue?: number | null,
      maxValue?: number | null,
      category?: string | null,
      restraints?: Array< string > | null,
      logic: string,
      sortChildId?: string | null,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      variation?: string | null,
      source?: string | null,
      sourceId?: string | null,
    } >,
    pages:  Array< {
      __typename: "Page",
      id: string,
      sheetId: string,
      rulesetId?: string | null,
      archetypeId?: string | null,
      characterId?: string | null,
      title: string,
      details: string,
      content: string,
      sortIndex: number,
      parentId?: string | null,
      sheet:  {
        __typename: "Sheet",
        id: string,
        rulesetId?: string | null,
        pageId?: string | null,
        rulesetTitle?: string | null,
        type: SheetType,
        templateId?: string | null,
        templateName?: string | null,
        templateType?: TemplateType | null,
        userId: string,
        username: string,
        version: number,
        title: string,
        description: string,
        image?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        backgroundImage?:  {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } | null,
        details: string,
        components:  Array< {
          __typename: "SheetComponent",
          id: string,
          sheetId: string,
          rulesetId: string,
          type: string,
          label: string,
          description?: string | null,
          images?:  Array< {
            __typename: "Image",
            id: string,
            src?: string | null,
            name: string,
            sortIndex: number,
            parentId?: string | null,
            details: string,
          } > | null,
          locked: boolean,
          tabId: string,
          layer: number,
          style: string,
          data: string,
          groupId?: string | null,
          x: number,
          y: number,
          width: number,
          height: number,
          rotation: number,
        } >,
        tabs: string,
      },
    } >,
    attributeData: string,
    itemData: string,
    image?:  {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } | null,
    sheet?:  {
      __typename: "Sheet",
      id: string,
      rulesetId?: string | null,
      pageId?: string | null,
      rulesetTitle?: string | null,
      type: SheetType,
      templateId?: string | null,
      templateName?: string | null,
      templateType?: TemplateType | null,
      userId: string,
      username: string,
      version: number,
      title: string,
      description: string,
      image?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      backgroundImage?:  {
        __typename: "Image",
        id: string,
        src?: string | null,
        name: string,
        sortIndex: number,
        parentId?: string | null,
        details: string,
      } | null,
      details: string,
      components:  Array< {
        __typename: "SheetComponent",
        id: string,
        sheetId: string,
        rulesetId: string,
        type: string,
        label: string,
        description?: string | null,
        images?:  Array< {
          __typename: "Image",
          id: string,
          src?: string | null,
          name: string,
          sortIndex: number,
          parentId?: string | null,
          details: string,
        } > | null,
        locked: boolean,
        tabId: string,
        layer: number,
        style: string,
        data: string,
        groupId?: string | null,
        x: number,
        y: number,
        width: number,
        height: number,
        rotation: number,
      } >,
      tabs: string,
    } | null,
    streamTabId?: string | null,
  },
};

export type StreamComponentsSubscriptionVariables = {
  input: GetSheetComponents,
};

export type StreamComponentsSubscription = {
  streamComponents:  Array< {
    __typename: "SheetComponent",
    id: string,
    sheetId: string,
    rulesetId: string,
    type: string,
    label: string,
    description?: string | null,
    images?:  Array< {
      __typename: "Image",
      id: string,
      src?: string | null,
      name: string,
      sortIndex: number,
      parentId?: string | null,
      details: string,
    } > | null,
    locked: boolean,
    tabId: string,
    layer: number,
    style: string,
    data: string,
    groupId?: string | null,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
  } >,
};
